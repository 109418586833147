<template>
    <page-content>

        <a-space align="start">
            <a-card title="分类管理">
                <common-table
                        ref="table"
                        path="web/machine/classify"
                        :columns="columns"
                >

                    <template slot="search">
                        <a-button ghost type="primary" @click="add">新增</a-button>
                    </template>

                    <template slot="operation" slot-scope="{record}">
                        <action-edit @click="edit(record)"></action-edit>
                        <action-delete @click="remove(record)"></action-delete>
                        <a-button size="small" @click="clickSub(record)">子分类管理</a-button>
                    </template>

                </common-table>
            </a-card>



            <a-card :title="parentClassify.name" v-if="parentClassify.id">
                <common-table
                        ref="childrenTable"
                        path="web/machine/classify"
                        :columns="columns"
                        :query-params="{parentId:this.parentClassify.id}"
                >

                    <template slot="search">
                        <a-button ghost type="primary" @click="addChild">新增</a-button>
                    </template>

                    <template slot="operation" slot-scope="{record}">
                        <action-edit @click="edit(record)"></action-edit>
                        <action-delete @click="remove(record)"></action-delete>
                    </template>

                </common-table>
            </a-card>

        </a-space>

        <ClassifyEdit ref="productEdit" @success="getList"></ClassifyEdit>
    </page-content>
</template>

<script>

    import ClassifyEdit from './ClassifyEdit'

    export default {
        name: 'Product',
        components: {
            ClassifyEdit
        },
        data() {
            return {
                parentClassify:{},
            }
        },

        computed: {
            columns() {
                return [
                    {
                        title: '分类名称',
                        dataIndex: 'name'
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'createdAt',
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        scopedSlots: {customRender: 'operation'}
                    }
                ]
            }
        },
        methods: {
            add() {
                this.$refs.productEdit.show()
            },
            addChild(){
                this.$refs.productEdit.show({parentId:this.parentClassify.id})
            },
            edit(record) {
                this.$refs.productEdit.show(record)
            },
            remove(record){
                let that = this
                this.$confirm({
                    title: '是否删除该记录?',
                    content: '该操作不可撤销',
                    centered: true,
                    onOk () {
                        that.$delete('web/machine/classify/' + record.id).then(() => {
                            that.$message.success('删除成功')
                            that.getList()
                            if(that.parentClassify.id == record.id){
                                that.parentClassify={}
                            }
                        })
                    }
                })
            },
            getList() {
                this.$refs.table.getData()
                this.$refs.childrenTable.getData()
            },
            clickSub(record){
                this.parentClassify=record
                this.$refs.childrenTable.getData()
            }
        }
    }
</script>

<style scoped>
.table-row{
    display: flex;

}
</style>
